import { breakpoints } from './theme';
import { css } from '@emotion/core';

export function truncate() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

const bp: any = {};
Object.keys(breakpoints).forEach((b) => {
  bp[b] = (...args: any) => css`
    @media (max-width: ${breakpoints[b]}px) {
      ${css(...args)}
    }
  `;
});

export const device: any = {};
Object.keys(breakpoints).forEach(
  // eslint-disable-next-line no-return-assign
  (b) => (device[b] = `(max-width: ${breakpoints[b]}px)`)
);
console.log(device);

export const breakpointsList = breakpoints;
export const media = bp;
