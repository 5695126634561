import * as React from 'react';

import NextOrangeImage from '../images/00Home/next@2x.png';
import { css } from '@emotion/core';
import { device } from '../styles/style-utils';
import iconCheck2xURL from '../images/01Trainer/checkmark@2x.png';
import playIconURL2x from '../images/Misc/play@2x.png';
import styled from '@emotion/styled';

export const Page = styled.div`
  display: flex;
  margin: auto;
  max-width: ${(props: any) => props.theme.dimensions.headerWrapperMaxWidth}px;
  //flex-shrink: 0;
  flex-grow: 1;
  flex-flow: row wrap;
  font-family: 'ProximaNovaRegular';
  color: ${(props) => props.theme.colors.dark2};

  /*padding-bottom: 128px;
  ${(props) => (props.noPaddingBottom ? 'padding-bottom:0;' : '')}
  @media ${device.tablet} {
    padding-bottom: 64px;
  }*/
`;

export const flexgutter = (props) => css`
  display: flex;
  padding-left: ${props.theme.dimensions.gutterSize}px;
  padding-right: ${props.theme.dimensions.gutterSize}px;
`;

export const Section = styled.div`
  margin-top: ${(props: any) =>
    props.first
      ? props.theme.dimensions.sectionSpacing / 2
      : props.theme.dimensions.sectionSpacing}px;
  margin-bottom: ${(props: any) =>
    props.last ? props.theme.dimensions.sectionSpacing : 0}px;

  @media ${device.tablet} {
    margin-top: ${(props: any) =>
      props.first
        ? props.theme.dimensions.sectionSpacing / 4
        : props.theme.dimensions.sectionSpacing / 2}px;
    margin-bottom: ${(props: any) =>
      props.last ? props.theme.dimensions.sectionSpacing / 2 : 0}px;
  }
`;

export const BannerTitle = styled.div`
  font-size: ${(props: any) => props.theme.textSizes.headerTitleSize}px;
  font-family: 'ProximaNovaExtraBold';
  line-height: 64px;
  ${(props) => props.noSubtitleSoMarginUp && 'margin-top: 46px;'}
  transition: all 0.3s ease-in;
  -webkit-font-smoothing: antialiased;
  color: ${(props: any) => props.theme.colors.black};
  @media ${device.tablet} {
    line-height: 40px;
    font-size: ${(props: any) => props.theme.textSizes.headerTitleTablet}px;
    margin-top: ${(props: any) => props.theme.textSizes.headerTitleTablet}px;
  }
`;

export const BannerButton = styled.div`
  margin-top: ${(props: any) => props.theme.textSizes.sectionLong}px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.dark5};
  text-align: center;
  font-family: 'ProximaNovaRegular';
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.lightsecondary};
  width: 288px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannerSubtitle = styled.div`
  font-size: ${(props: any) => props.theme.textSizes.headerMenu}px;
  font-family: 'ProximaNovaRegular';
  line-height: 24px;
  padding-right: 40px;
  transition: all 0.3s ease-in;
  color: ${(props: any) => props.theme.colors.dark2};
  margin-top: ${(props: any) => props.theme.textSizes.headerMenu}px;
  @media ${device.tablet} {
    line-height: 18px;
    padding-right: 90px;
    margin-bottom: -10px;
    font-size: ${(props: any) => props.theme.textSizes.smallText}px;
  }
`;

export const BannerTextWrapper = styled.div`
/* ${flexgutter}; */
flex: 1.15;
padding: 2.2rem;
@media ${device.tablet} {
  padding: 0px;
}
`;

export const HalfPageBackground = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  margin-left: -15px; // Push back to the border of the window
  margin-right: -15px; // Cancels gutters
  background: ${(props: any) => props.theme.colors.backgroundalternate};
  z-index: -1;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const BannerVideoOrImageWrapper = styled.div`
  flex: 1;
  padding-top: 47px;
  ${(props) => props.onClick && 'cursor: pointer;'}
`;

export const BannerImage = styled.div`
background-repeat: no-repeat;
background-size: cover;
background-position: 50% 50%;
padding-top: 64.63%;
/* filter: brightness(1); */
border-radius: 10px;
background-image: url(${(props) => props.imgUrl});
// background: ${(props) => props.theme.colors.dark3};
position: relative;
&:before {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  background-image: url(${playIconURL2x});
  content: '';
  left: 0;
  right: 0;
  // z-index: 1;
  bottom: 0;
  top: 0;
  background-position: 50% 50%;
  ${(props) => props.noPlayIcon && 'display:none;'}
}

`;

export const BannerAndText = styled.div`
height: 540px;
${flexgutter};
display: flex;
position: relative;

${(props) => (props.flipped ? 'flex-flow: row-reverse;' : '')}
${Page} {
  ${(props) => (props.flipped ? 'flex-flow: row-reverse;' : '')}
}
${HalfPageBackground} {
      ${(props) =>
        props.flipped &&
        'margin-right:0;margin-left: 15px;border-radius: 10px;border-top-right-radius: 0;border-bottom-right-radius: 0;'}
}
@media ${device.handheld} {
  height: auto;
  margin-top: 0;
  ${Page} {
    flex-flow: wrap-reverse;
  }

  ${BannerVideoOrImageWrapper},${BannerTextWrapper} {
    flex-basis: 100%;
  }
  ${BannerSubtitle} {
    padding:0;
    margin-bottom:0;
    margin-top: 24px;
  }
  ${HalfPageBackground} {
    width:100%;
    border-radius:0;
    opacity: .7;
  }
}
`;
// The transform on the fly might not work on all
// browser if it doesnt get transpiled,
// In this case include the url() of the prev img src
export const ArrowDiv = styled.div`
  border-radius: 50%;
  border-radius: 50% 50%;
  box-shadow: 0px 0px 5px #ccc3c3;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
  background-image: url(${NextOrangeImage}) !important;
  z-index: 1;
  ${(props) =>
    props.direction &&
    'left:10% !important;right: auto !important;transform: translate(0,-50%) scaleX(-1) !important;'}
  &:before {
    content: '' !important;
  }
`;

export const NextArrowFn = (props) => {
  const { className, style, onClick } = props;
  return <ArrowDiv className={className} onClick={onClick} />;
};

export const PrevArrowFn = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowDiv direction="previous" className={className} onClick={onClick} />
  );
};

export const IndexBlackSectionTitle = styled.h3`
  font-size: ${(props: any) => props.theme.textSizes.sectionTitle}px;
  font-family: 'ProximaNovaExtraBold';
  line-height: 64px;
  letter-spacing: 0;
  margin-bottom: 4rem;
  /*margin-top: ${(props: any) => props.theme.dimensions.marginTopH3}px;*/
  flex-grow: 1;
  flex-basis: 100%;
  transition: all 0.3s ease-in;
  -webkit-font-smoothing: antialiased;
  color: ${(props: any) => props.theme.colors.black};
  @media ${device.tablet} {
    line-height: 40px;
    /* margin-top: ${(props: any) =>
      props.theme.dimensions.marginTopH3Small}px; */
    font-size: ${(props: any) => props.theme.textSizes.sectionSmall}px;
  }
  @media ${device.handheld} {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

export const StyledIcon = styled.img`
  width: 58px;
  height: 58px;
  margin-bottom: 0;
`;

export const IconBlock = (props) => <StyledIcon alt="img" src={props.url} />;

export const IconCheck = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  /* flex: 34px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  float: left;
  margin-right: 12px;
  background-image: url(${iconCheck2xURL});
`;

export const IndexSub = styled.div`
  display: flex;
  p {
    clear: both;
  }
`;

export const IndexSubtitle = styled.h4`
  font-family: 'ProximaNovaExtraBold';
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: ${(props) => props.theme.textSizes.sectionSubtitle}px;
  line-height: ${(props) => props.theme.textSizes.headerTitleTablet}px;
  a {
    color: ${(props) => props.theme.colors.black};
    // padding-right: 10px;
    display: flex;
    align-items: center;
  }
  svg {
    path {
      fill: ${(props) => props.theme.colors.lightorange};
    }
  }

  margin-top: 20px;
`;
export const IconTextWrapper = styled.div`
  display: flex;
  //align-items: space-around;
  flex-flow: row wrap;
  flex: 1;
  margin: -1vw;
  justify-content: space-between;
  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export const IconTextSubtext = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1vw;
  max-width: 380px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.lightorange};
    }
  }

  @media ${device.desktop} {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 32px;
  }

  ${IndexSubtitle} {
    @media ${device.tablet} {
      // Todo: Make helper
      line-height: ${(props: any) => props.theme.textSizes.sectionSmall}px;
      font-size: ${(props: any) => props.theme.textSizes.headerMenu}px;
    }
    color: ${(props: any) => props.theme.colors.black};
  }
`;

export const IndexText = styled.div`
  @media ${device.tablet} {
    // Todo: Make helper
    line-height: ${(props: any) => props.theme.dimensions.lineHeightSmall}px;
    font-size: ${(props: any) => props.theme.textSizes.smallText}px;
  }
  line-height: ${(props) => props.theme.textSizes.sectionSmall}px;
`;
